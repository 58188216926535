import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ContentCollapse } from 'client/site-modules/shared/components/content-collapse/content-collapse';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { highlight } from './highlight-review';

const MAX_CONTAINER_HEIGHT = 144; // 6 lines of text at line-height 24px
const WORDS_BEFORE_HIGHLIGHT_THRESHOLD = 20;
const HIGHLIGHT_SPAN = '<span class="highlight">';

export function FormattedReviewTextByNLPInference({ review: { text, spans }, btnClassName, hideIndicator }) {
  const [isExpandable, setExpandable] = useState(false);
  const [height, setHeight] = useState(MAX_CONTAINER_HEIGHT);
  const containerRef = React.useRef();
  const truncatedRef = React.useRef();

  useEffect(() => {
    const reviewHeight = get(containerRef, 'current.container.offsetHeight', MAX_CONTAINER_HEIGHT);

    setExpandable(reviewHeight > MAX_CONTAINER_HEIGHT);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const truncatedReviewHeight = Math.min(
      get(truncatedRef, 'current.container.offsetHeight', MAX_CONTAINER_HEIGHT),
      MAX_CONTAINER_HEIGHT
    );

    setHeight(truncatedReviewHeight);
  });

  const inferenceArr = spans.map(({ start, end }) => [start, end]);
  const highlightedReview = highlight(text, inferenceArr);

  const highlightedSections = highlightedReview.split(HIGHLIGHT_SPAN);
  const wordsBeforeHighlight = highlightedSections[0].trim().split(/\s+/);
  const truncatedReviewBlock =
    wordsBeforeHighlight.length > WORDS_BEFORE_HIGHLIGHT_THRESHOLD ? (
      <ContentFragment classes="review-text" ref={truncatedRef}>
        {`...${wordsBeforeHighlight
          .slice(wordsBeforeHighlight.length - WORDS_BEFORE_HIGHLIGHT_THRESHOLD)
          .join(' ')
          .concat(` ${HIGHLIGHT_SPAN}`, highlightedSections.slice(1).join(HIGHLIGHT_SPAN))}`}
      </ContentFragment>
    ) : null;

  const reviewBlock = (
    <ContentFragment classes="review-text" ref={containerRef}>
      {highlightedReview}
    </ContentFragment>
  );

  return isExpandable ? (
    <ContentCollapse
      minHeight={`${height}px`}
      justify="justify-content-start"
      btnClasses={btnClassName}
      hideIndicator={hideIndicator}
      btnArrowClasses="xsmall"
      btnContainerClasses="p-0"
      isEclipseFade={false}
      showBtnBelow
      key={inferenceArr.toString()}
      truncatedChildren={truncatedReviewBlock}
    >
      {reviewBlock}
    </ContentCollapse>
  ) : (
    reviewBlock
  );
}

FormattedReviewTextByNLPInference.propTypes = {
  review: PropTypes.shape({
    text: PropTypes.string.isRequired,
    spans: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.number,
        end: PropTypes.number,
      })
    ),
  }).isRequired,
  btnClassName: PropTypes.string,
  hideIndicator: PropTypes.bool,
};

FormattedReviewTextByNLPInference.defaultProps = {
  btnClassName: 'text-capitalize font-weight-normal text-primary-darker heading-5 mb-0_25 px-0_5 py-0',
  hideIndicator: false,
};
