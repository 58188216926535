import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import dateFormat from 'dateformat';
import { compact, get } from 'lodash';

import { SCROLL_OFFSET, SCROLL_DURATION } from 'site-modules/shared/constants/sub-navigation';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { TruncatedText } from 'site-modules/shared/components/truncated-text/truncated-text';
import { RatingStars } from 'site-modules/shared/components/rating-stars/rating-stars';
import { FormattedReviewTextByNLPInference } from 'site-modules/shared/components/reviews/formatted-review-text-by-nlp-inference';
import { adjustTimezone } from 'site-modules/shared/utils/date-utils';

const DATE_STR_REGEXP = /\d{2}-\d{2}-\d{4}/g;

export function Review({
  mmsy: { make: makeName, model: modelName, year },
  review,
  isMobile,
  classes,
  index,
  reviewLength,
  heading: Heading,
  isCore5497Enabled,
}) {
  const authorName = get(review, 'author.authorName');
  const {
    id,
    vehicleRating,
    userRating,
    userName,
    createTime,
    createDate,
    title,
    styleName,
    created,
    style,
    text,
    spans,
    year: reviewYear,
    city,
    state,
  } = review;
  const name = userName || authorName || '';
  // vehicleRating comes from consumerReviews graphql call,
  // userRating comes from srp inventoryData binded to InventoryModel
  const overallRating = (vehicleRating ? vehicleRating.overall : userRating) || 0;
  const key = index + 1;
  const date = createTime || createDate || created || '';
  const formattedDate =
    date &&
    (date.match(DATE_STR_REGEXP) ? date.replace(/-/g, '/') : dateFormat(adjustTimezone(new Date(date)), 'mm/dd/yyyy'));

  return (
    <div className={classnames('text-gray-darker', classes)} data-index={key}>
      <ScrollElement id={`scrollTop-${id}`} />
      {!isCore5497Enabled && <RatingStars className="text-primary-darker medium" rating={overallRating} />}
      <Heading
        className={classnames('user-title', {
          'd-inline text-primary-darker size-16 font-weight-medium ml-0_5 ml-md-0_5': !isCore5497Enabled,
          'heading-5 mb-0_25': isCore5497Enabled,
        })}
      >
        {title || ''}
      </Heading>
      {isCore5497Enabled && <RatingStars className="text-blue-50" rating={overallRating} />}
      <div className="small text-cool-gray-40 mb-0_5">
        <div>{compact([name, city, state, formattedDate]).join(', ')}</div>
        <div>
          {reviewYear || year} {makeName} {modelName} {styleName || (style && style.name)}
        </div>
      </div>
      {!!text &&
        (get(spans, 'length') ? (
          <FormattedReviewTextByNLPInference review={review} isMobile={isMobile} key={id} />
        ) : (
          <div className="review-text size-16">
            {text.length > reviewLength ? (
              <TruncatedText
                text={text}
                btnClassName={classnames('read-more text-primary-darker font-weight-normal size-16', {
                  'mt-0_5': isCore5497Enabled,
                })}
                btnTextMore="Read more"
                btnTextLess="Read less"
                indicatorOpenClass="icon-arrow-down4 size-10 pl-0_5"
                indicatorCloseClass="icon-arrow-up4 size-10 pl-0_5"
                maxTextLength={reviewLength}
                className="size-16 font-weight-normal"
                expandTrackingId="view_consumer_review"
                expandTrackingValue="Read more"
                expandAriaLabel={`Read more of review: ${title}`}
                collapseAriaLabel={`Read less of review: ${title}`}
                resetParams={{
                  offset: SCROLL_OFFSET,
                  duration: SCROLL_DURATION,
                  to: `scrollTop-${id}`,
                }}
              />
            ) : (
              text
            )}
          </div>
        ))}
    </div>
  );
}

Review.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.string.isRequired,
    author: PropTypes.shape({
      authorName: PropTypes.string,
    }),
    created: PropTypes.string,
    city: PropTypes.string,
    createTime: PropTypes.string,
    createDate: PropTypes.string,
    spans: PropTypes.arrayOf(
      PropTypes.shape({
        start: PropTypes.number,
        end: PropTypes.number,
      })
    ),
    state: PropTypes.string,
    style: PropTypes.shape({
      name: PropTypes.string,
    }),
    styleName: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    userName: PropTypes.string,
    userRating: PropTypes.number,
    vehicleRating: PropTypes.shape({
      overall: PropTypes.number,
    }),
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  mmsy: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    make: PropTypes.string,
    model: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool,
  classes: PropTypes.string,
  index: PropTypes.number.isRequired,
  reviewLength: PropTypes.number.isRequired,
  heading: PropTypes.node,
  isCore5497Enabled: PropTypes.bool,
};

Review.defaultProps = {
  review: {},
  isMobile: false,
  classes: null,
  heading: 'h3',
  isCore5497Enabled: false,
};
